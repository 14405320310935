const TourOperator = [
  { value: 'AIR_CANADA', text: 'Air Canada' },
  { value: 'AIR_FRANCE', text: 'Air France' },
  { value: 'AIRNETS', text: 'Airnets International' },
  { value: 'ALITALIA', text: 'Alitalia' },
  { value: 'AMA_WATERWAYS', text: 'AMA Waterways' },
  { value: 'AMERICA_AIRLINES', text: 'America Airlines' },
  { value: 'ASIA_COLLECTION', text: 'Asia Collection' },
  { value: 'BEDS_ONLINE', text: 'Beds Online' },
  { value: 'BRITISH_AIRWAYS', text: 'British Airways' },
  { value: 'CELEBRITY_CRUISES', text: 'Celebrity Cruises' },
  { value: 'COLLETTE_VACATIONS', text: 'Collette Vacations' },
  { value: 'CONTIKI', text: 'Contiki' },
  { value: 'CLUB_MED', text: 'Club Med'},
  { value: 'ENCORECRUISE' , text: 'Croisières Encore'},
  { value: 'DELTA_AIRLINES', text: 'Delta Airlines' },
  { value: 'DISNEY_CRUISE_LINE', text: 'Disney Cruise Line' },
  { value: 'EMERALD_WATERWAYS', text: 'Emerald Waterways' },
  { value: 'EXODUS', text: 'Exodus' },
  { value: 'EXOTIC_TOUR' , text: 'Exotik Tours'},
  { value: 'EXOTICCA', text: 'Exoticca' },
  { value: 'EXPEDIA', text: 'Expedia' },
  { value: 'FINNAIR', text: 'Finnair' },
  { value: 'G_ADVENTURE', text: 'G Adventure' },
  { value: 'GROUPE_VOYAGE_QUEBEC' , text: 'Groupes Voyages Québec'},
  { value: 'HOLLAND_AMERICA_LINE', text: 'Holland America Line' },
  { value: 'IBERIA', text: 'Iberia' },
  { value: 'INTAIR', text: 'Intair' },
  { value: 'JAPAN_AIRLINES', text: 'Japan Airlines' },
  { value: 'JUST_YOU', text: 'Just You' },
  { value: 'KLM', text: 'KLM' },
  { value: 'AVENTURES_EOLIENNES', text: 'Les Aventures Éoliennes' },
  { value: 'OCEANIA_CRUISES', text: 'Oceania Cruises' },
  { value: 'NEXUS_HOLIDAYS', text: 'Nexus Holidays' },
  { value: 'NORWEGIAN_CRUISE_LINE' , text: 'Norwegian Cruise Line'},
  { value: 'PRINCESS', text: 'Princess' },
  { value: 'REGENT', text: 'Regent' },
  { value: 'ROCKY_MOUTAINEER', text: 'Rocky Moutaineer' },
  { value: 'ROYAL_CARRIBEAN' , text: 'Royal Caribbean'},
  { value: 'SEABOURN_CRUISES', text: 'Seabourn Cruises' },
  { value: 'SKYLINK', text: 'Skylink' },
  { value: 'SULTANA_TOURS' , text: 'Sultana Tours'},
  { value: 'SUNQUEST', text: 'Sunquest' },
  { value: 'SUNWING', text: 'Sunwing' },
  { value: 'TOUR_EAST', text: 'Tour East' },
  { value: 'TOURCHANTECLERC' , text: 'Tours Chanteclerc'},
  { value: 'TOURCHANTECLERC_AMERIQUE' , text: 'Tours Chanteclerc - Amérique'},
  { value: 'TOURS_CHANTECLERC_ASIE', text: 'Tours Chanteclerc - Asie' },
  { value: 'TOURS_CHANTECLERC_EUROPE', text: 'Tours Chanteclerc - Europe' },
  { value: 'TOURS_CHANTECLERC_PACIFIC_SUD', text: 'Tours Chanteclerc - Pacific Sud' },
  { value: 'TOURS_CHANTECLERC_CROISIÈRES', text: 'Tours Chanteclerc Croisières' },
  { value: 'TOURS_CURE_VAC', text: 'Tours Cure-Vac' },
  { value: 'TRAFALGAR', text: 'Trafalgar' },
  { value: 'ULTIMATE_JET_VACATIONS', text: 'Ultimate Jet Vacations' },
  { value: 'UNIWORLD', text: 'Uniworld' },
  { value: 'TRAVELBAND' , text: 'Travelbrands'},
  { value: 'TRANSAT' , text: 'Transat'},
  { value: 'TRANSAT_EUROPE' , text: 'Transat Europe'},
  { value: 'TRANSAT_SUD' , text: 'Transat Sud'},
  { value: 'VACANCES_AIR_CANADA' , text: 'Vacances Air Canada'},
  { value: 'VIATOR', text: 'Viator' },
  { value: 'VIKING_RIVER', text: 'Viking River' },
  { value: 'VIRGIN_VOYAGES', text: 'Virgin Voyages' },
  { value: 'WESTJET', text: 'WestJet' },
  { value: 'WINDSTAR_CRUISES', text: 'Windstar Cruises' },
  { value: 'OTHERS', text: 'Autre' }
  //{ value: 'SENTIERS_DU_MONDE', text: 'Sentiers du monde' },
];
const transportationTourOperators = TourOperator.map(x => {
  let newValue = {text: x.text, value: x.value.toLowerCase()};
  switch (newValue.value) {
    case 'skylink':
      newValue.value = 'skyling'
      break;
    case 'tourchanteclerc':
      newValue.value = 'tour_chanteclerc'
      break;
    case 'sultana_tours':
      newValue.value = 'sultana'
      break;
    case 'travelband':
      newValue.value = 'travel_brand'
      break;
  }
  return newValue;
})

export default {
  transportType: [
    { value: 'coach', text: 'Autocar' },
    { value: 'flight', text: 'Vol' },
  ],
  tourOperators: transportationTourOperators,
  seatsSelection: [
    { value: 'NONE', text: 'Sélection de siège non disponible' },
    { value: 'PAID', text: 'Sélection de siège payante' },
    { value: 'GROUP_PRICE', text: 'Siège attribuée en groupe gratuit - Aucun choix possible.' },
    { value: 'EXTRA', text: 'Surclassement disponible avec siège confort (extra$)' },
  ],
  type: [
    { value: 'CRUISE', text: 'Croisière' },
    { value: 'CIRCUIT', text: 'Circuit' },
    { value: 'ACCOMMODATION', text: 'Séjour' },
    { value: 'THEMATIC', text: 'Voyage thématique' },
  ],
  groupDifficulty: [
    { value: 'EASY', text: 'Facile' },
    { value: 'NORMAL', text: 'Normale' },
    { value: 'DIFFICULT', text: 'Difficile' },
    { value: 'NONE', text: 'Aucune' },

  ],
  accommodationTourOperators: TourOperator,
  accommodationType: [
    { value: 'CRUISE_SHIP' , text: 'Navire de croisière'},
    { value: 'HOTEL_SPECIFIC' , text: 'Hôtel spécifique'},
    { value: 'HOTEL_GENERIC' , text: 'Hôtel générique'},
  ],
  roomOption: [
    { value: 'INTERIOR' , text: 'Intérieur'},
    { value: 'EXTERIOR' , text: 'Extérieur'},
    { value: 'BALCONY' , text: 'Balcon'},
    { value: 'SUITE' , text: 'Suite'},
  ],
  childrenCategories: [
    [{text: "Bébé moins de 2 ans", value: "BABY_TWO_YEARS_OR_LESS"},
    {text: "Bébé 2 ans", value: "BABY_TWO_YEARS"},
    {text: "Enfant 2 à 12 ans", value: "CHILD_TWO_TO_TWELVE"},
    {text: "Enfant 3 à 11 ans", value: "CHILD_THREE_TO_ELEVEN"},
    {text: "Enfant 3 à 12 ans", value: "CHILD_THREE_TO_TWELVE"},
    {text: "Enfant 2 à 5 ans", value: "CHILD_TWO_TO_FIVE"},
    {text: "Enfant 5 à 12 ans", value: "CHILD_FIVE_TO_TWELVE"},
    {text: "Ado 13 à 17 ans", value: "TEEN_THIRTEEN_TO_SEVENTEEN"}],
  ]
}
